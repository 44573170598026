import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby-plugin-react-intl";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import "./styling/404.css";

const NotFoundPage = (props) => {
  return (
    <div className="wrapper">
      <Helmet title="404 | narando" />
      <h3>
        <FormattedMessage id="404.header" />
      </h3>
      <p>
        <FormattedMessage id="404.text" />
      </p>
      <Link to="/">
        <button className="backbtn">
          <FormattedMessage id="404.button" />
        </button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
